import clsx from "clsx"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IReview } from "../../types/content"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./Review.module.scss"

interface Props extends IReview {
  className?: string
}

const Review = ({ order_id, order_name, text, created_at, assigneeUsers, rating, className }: Props) => {
  return (
    <div className={clsx(styles.item, className)}>
      <h4 className={styles.title}>{order_name}</h4>
      {text && <p className={styles.txt}>{text}</p>}
      <div className={styles.rateSection}>
        {assigneeUsers?.length > 0 && (
          <GetUserAvatar
            avatar={assigneeUsers[0]?.avatar_id}
            name={assigneeUsers[0]?.name}
            className={styles.avatar}
            size={"sm"}
          />
        )}
        <div>
          {assigneeUsers?.length > 0 && <p className={styles.name}>{assigneeUsers[0].name}</p>}
          <StarsRating rating={rating} size={"sm"} name={"review-rate-" + order_id} disabled />
        </div>
        <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>
      </div>
    </div>
  )
}

export default Review
