import { useEffect } from "react"
import { BrowserRouter as Router, useRoutes } from "react-router-dom"
import Home from "./pages"
import History from "./pages/history"
import Orders from "./pages/orders"
import Products from "./pages/category/[category_id]/[category_id]"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import "./utils/i18n"
import WithAuth from "./hoc/withAuth"
import RequireAuth from "./hoc/RequireAuth"
import { setDefaultLocale } from "react-datepicker"
import moment from "moment"
import "moment/locale/ru"
// import "moment/locale/fr"
// import "moment/locale/zh-cn"
import "moment/locale/th"
import { getKeyLangMap, langWithCurrentMomentCode } from "./utils/helpers"
import { registerSW } from "./utils/serviceWorkerRegistration"
import Page404 from "./pages/404"
import ReviewsPage from "./pages/reviews"
import EmployeesPage from "./pages/employees"
import ArticlesPage from "./pages/articles/[...id]"
import OrderServicePage from "./pages/category/[category_id]/[service_id]"
import OrderInfoPage from "./pages/order/[id]"
import ObjectPageWrap from "./pages/object/[object_id]"
import { Tooltip } from "react-tooltip"
import MainLayout from "./components/MainLayout/MainLayout"
import ProductsLayout from "./components/ProductsLayout/ProductsLayout"
import {
  MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
  OG_DESCRIPTION,
  OG_TITLE,
  PAGE_TITLE,
  STAQ_VERSION_COOKIE
} from "./utils/constants"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import {getCookie, setCookie} from "./utils/cookies";

const RoutesArr = () =>
  useRoutes(
    [
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: ":locale/",
        element: <Home />,
      },
      {
        path: "/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: "/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: "/reviews",
        element: (
          <RequireAuth>
            <ReviewsPage />
          </RequireAuth>
        ),
      },
      {
        path: "/employees",
        element: (
          <RequireAuth>
            <EmployeesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles/:id",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/category/:category_id",
        element: <Products />,
      },
      {
        path: ":locale/category/:category_id",
        element: <Products />,
      },
      {
        path: "/category/:category_id/:service_id",
        element: (
          <RequireAuth>
            <OrderServicePage />
          </RequireAuth>
        ),
      },
      {
        path: "/order/:id",
        element: (
          <RequireAuth>
            <OrderInfoPage />
          </RequireAuth>
        ),
      },
      {
        path: "/object/:object_id/",
        element: <ObjectPageWrap />,
        children: [
          {
            index: true,
            element: <MainLayout />,
          },
          {
            path: "category/:category_id",
            element: <ProductsLayout />,
          },
        ],
      },
      {
        path: "/object/:object_id/category/:category_id/:service_id",
        element: (
          <RequireAuth>
            <OrderServicePage />
          </RequireAuth>
        ),
      },
    ].map((el) => ({ path: el.path, element: el.element, children: el.children })),
  )

function App() {
  const currentLang = localStorage.getItem("i18nextLng")
  const { i18n } = useTranslation()
  const userLang = navigator.language
  const keyLang = getKeyLangMap(userLang)

  useEffect(() => {
    if (getCookie(STAQ_VERSION_COOKIE) !== String(process.env.REACT_APP_PUBLIC_STAQ_VERSION)) {
      if (typeof window !== 'undefined') {
        const allCookies = document.cookie.split(';')
        for (let i = 0; i < allCookies.length; i++) {
          const cookie = allCookies[i]
          const eqPos = cookie.indexOf('=')
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
        }
        sessionStorage.clear()
        localStorage.clear()
        setCookie(STAQ_VERSION_COOKIE, String(process.env.REACT_APP_PUBLIC_STAQ_VERSION), {
          maxAge: MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
          sameSite: 'lax',
        })
      }
    }
  }, [])

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
      if (currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)) {
        void i18n.init({ lng: keyLang ? keyLang[0] : "en" })
      } else {
        void i18n.init({ lng: currentLang })
      }
    } else {
      moment.locale("en")
      void i18n.init({ lng: keyLang ? keyLang[0] : "en" })
    }
    setDefaultLocale(String(currentLang) || "en")
  }, [currentLang])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta property="og:site_name" content={PAGE_TITLE} />
        <meta property="og:title" content={OG_TITLE} />
        <meta property="og:description" content={OG_DESCRIPTION} />
      </Helmet>
      <Provider store={store}>
        <WithAuth />
        <Router>
          <RoutesArr />
        </Router>
        <Tooltip id="globalTooltip" className={"tooltip-base"} place="top" />
      </Provider>
    </HelmetProvider>
  )
}

export default App
